document.addEventListener('DOMContentLoaded', () => {
    const hamburger = document.querySelector('.hamburger');
    const navbar = document.querySelector('.navbar');

    hamburger.addEventListener('click', () => {
        navbar.classList.toggle('active');
    });
});

window.addEventListener('load', () => {
  var carousels = document.querySelectorAll('.carousel');
  for (var i = 0; i < carousels.length; i++) {
      carousel(carousels[i]);
  }
});

function carousel(root) {
    var figure = root.querySelector('figure'),
        nav = root.querySelectorAll('nav'),
        items = figure.children,
        n = items.length,
        gap = root.dataset.gap || 0,
        bfc = 'bfc' in root.dataset,
        theta = 2 * Math.PI / n,
        currItem = 0,
        startX = 0,
        startTheta = 0, 
        dragFactor = 250,
        isScrolling = undefined,
        newTheta = 0;

    setupCarousel(n, parseFloat(getComputedStyle(items[0]).width));
    window.addEventListener('resize', () => {
        setupCarousel(n, parseFloat(getComputedStyle(items[0]).width))
    });

    setupNavigation();
    setupDragging();

    function setupCarousel(n, s) {
        var apothem = s / (2 * Math.tan(Math.PI / n));
        figure.style.transformOrigin = `50% 50% ${-apothem}px`;

        for (var i = 0; i < n; i++)
            items[i].style.padding = `${gap}px`;
        for (i = 1; i < n; i++) {
            items[i].style.transformOrigin = `50% 50% ${-apothem}px`;
            items[i].style.transform = `rotateY(${i * theta}rad)`;
        }
        if (bfc)
            for (i = 0; i < n; i++)
                items[i].style.backfaceVisibility = 'hidden';

        rotateCarousel(currItem);
    }

    function setupNavigation() {
        nav.forEach((el) => { el.addEventListener('click', onClick, true) });

        function onClick(e) {
            e.stopPropagation();

            var t = e.target;
            if (t.tagName.toUpperCase() != 'BUTTON')
                return;

            if (t.classList.contains('next')) {
                currItem++;
            } else {
                currItem--;
            }

            rotateCarousel(currItem);
        }
    }

    function rotateCarousel(itemIndex) {
        figure.style.transform = `rotateY(${itemIndex * -theta}rad)`;
    }

    // Added for dragging
    function setupDragging() {
        // For mouse events
        figure.addEventListener('mousedown', (e) => {
            e.preventDefault();
            startX = e.clientX;
            startTheta = currItem * theta;
            document.addEventListener('mousemove', onDrag);
            document.addEventListener('mouseup', onStopDrag);
        });
    
        // For touch events
        figure.addEventListener('touchstart', (e) => {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY; // Track the starting Y position
            startTheta = currItem * theta;
            isScrolling = undefined; // Reset the scrolling flag
            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('touchend', onStopDrag);
        });
    
        function onDrag(e) {
            var dx = e.clientX - startX;
            newTheta = startTheta - dx / dragFactor; // Adjust the divisor to control sensitivity, and subtract dx to reverse direction
            figure.style.transform = `rotateY(${-newTheta}rad)`;
        }
    
        function onTouchMove(e) {
            var dx = e.touches[0].clientX - startX;
            var dy = e.touches[0].clientY - startY;
    
            // Determine if the user is scrolling or rotating
            if (typeof isScrolling === 'undefined') {
                isScrolling = Math.abs(dy) > Math.abs(dx);
            }
    
            if (!isScrolling) {
                e.preventDefault(); // Prevent scrolling when rotating
                newTheta = startTheta - dx / dragFactor; // Adjust the divisor to control sensitivity, and subtract dx to reverse direction
                figure.style.transform = `rotateY(${-newTheta}rad)`;
            }
        }
    
        function onStopDrag(e) {
            document.removeEventListener('mousemove', onDrag);
            document.removeEventListener('mouseup', onStopDrag);
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', onStopDrag);
            // Snap to nearest item if not scrolling
            if (!isScrolling) {
                currItem = Math.round(newTheta / theta);
                rotateCarousel(currItem);
            }
        }
    }
    
}

const parallaxImages = document.querySelectorAll(".parallax-image");

window.addEventListener("scroll", () => {
    invokeParallax();
});

function invokeParallax() {
    const scrollValue = window.scrollY;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    // Apply transformations without including scale
    const setTransform = (parallaxImage, translateY, rotate, translateX) => {
        let transform = `translateY(${translateY}px)`;
        if (rotate) transform += ` rotate(${rotate}deg)`;
        if (translateX) transform += ` translateX(${translateX}px)`;
        parallaxImage.style.transform = transform;
    };

    parallaxImages.forEach((parallaxImage) => {
        const parallaxSpeed = parallaxImage.dataset.speed;
        const topPosition = -(scrollValue * parallaxSpeed) / 100;

        switch (true) {
            case parallaxImage.src.includes('retroSunTransparent'):
                setTransform(parallaxImage, topPosition, topPosition / 5, null);
                break;
            case parallaxImage.src.includes('fog'):
                setTransform(parallaxImage, topPosition, null, vw < 768 ? topPosition / -3.5 : topPosition / -5);
                break;
            case parallaxImage.src.includes('Layer'):
                setTransform(parallaxImage, topPosition, null, null);
                break;
            default:
                setTransform(parallaxImage, topPosition, null, null);
        }
    });
}

invokeParallax();